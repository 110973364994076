.logo-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-collapsed {
  height: 40px;
  margin: 10px 18px;
}

.logo {
  height: 40px;
  width: 40px;
  margin-top: 23px;
  margin-left: 20px;
}

.logo-text {
  color: white;
  font-size: 22px;
  font-weight: bolder;
}

.login-logo-container {
  align-items: center;
  cursor: pointer;
}

.login-logo {
  height: 60px;
  width: 60px;
  margin: 16px 16px 16px 0;
}

.login-logo-text {
  color: black;
  margin-top: 0.5em;
  font-size: 28px;
  font-weight: 600;
  width: 240px;
}
